<template>
  <div>
    <vue-advanced-chat
      height="calc(100vh)"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :single-room="JSON.stringify(true)"
      :menu-actions="[]"
      :message-actions="[]"
      :show-reaction-emojis="JSON.stringify(false)"
      :show-emojis="JSON.stringify(false)"
      :emojis-suggestion-enabled="JSON.stringify(false)"
      :show-new-messages-divider="JSON.stringify(false)"
      :show-audio="JSON.stringify(false)"
      :show-files="JSON.stringify(false)"
      :styles="JSON.stringify(styles)"
      :show-send-icon="JSON.stringify(showSend)"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
    >
      <div :slot="'message_' + 'loading'">
        <div class="flex-grow-0 ml-1 msg-style">
          <div class="p-1 px-2 m-0">
            <div class="col-3">
              <div class="snippet" data-title="dot-pulse">
                <div class="stage">
                  <div class="dot-pulse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-advanced-chat>
  </div>
</template>

<script>
import { register } from "vue-advanced-chat";
import { darktheme } from "./themedata";

register();

export default {
  data() {
    return {
      messages: [],
      showSend: true,
      messagesLoaded: false,
      currentUserId: "1234",
      rooms: [
        {
          roomId: "1",
          roomName: "Time Phantom Bot",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/team-dynamics-v2.appspot.com/o/ale-bot.png?alt=media&token=fc80bb67-422f-4f21-87c8-8732410c30cc",
          users: [
            { _id: "1234", username: "John Doe" },
            { _id: "bot", username: "Time Phantom Bot" },
          ],
        },
      ],
      styles: darktheme(),
      loadingMesage: {
        _id: "loading",
        senderId: "bot",
        username: "Loading",
        content: "Loading Please wait...",
        timestamp: new Date().toString().substring(16, 21),
        date: new Date().toDateString(),
        // files: [
        // 	{
        // 		type: 'svg',
        // 		url: 'loading.svg'
        // 	}
        // ],
      },
    };
  },

  methods: {
    fetchMessages() {
      setTimeout(() => {
        this.messages = [
          {
            _id: "bot",
            content: `Hi,\nI am Time Phantom Bot.\nYou can ask me any question from articles availble on this platform`,
            senderId: "bot",
            username: "Time Phantom Bot",
            timestamp: new Date().toString().substring(16, 21),
            date: new Date().toDateString(),
          },
        ];
        this.messagesLoaded = true;
      }, 2000);
    },

    sendMessage(message) {
      this.showSend = false;
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString(),
        },
        this.loadingMesage,
      ];
      var payload = {
        question: message.content,
        messages: this.messages.map((e) => {
          return {
            role: e._id == "bot" ? "assistant" : "user",
            content: e.content,
          };
        }),
        id: "65bcdc7f4530bdde86f908f2",
        model: "gpt-3.5-turbo",
      };
      this.$store
        .dispatch("bot/getCompleteAnswer", payload)
        .then((data) => {
          console.log(data);
          this.messages.pop();
          var newMessages = [
            ...this.messages,
            {
              _id: "bot",
              content: data.message,
              senderId: "bot",
              username: "Time Phantom Bot",
              timestamp: new Date().toString().substring(16, 21),
              date: new Date().toDateString(),
            },
          ];
          this.messages = [...newMessages];
        })
        .catch((err) => {
          console.log(err);
          this.messages = [
            ...this.messages,
            {
              _id: "bot",
              senderId: "bot",
              system: true,
              username: "Time Phantom Bot",
              content: "Something went wrong plese try again later",
              timestamp: new Date().toString().substring(16, 21),
              date: new Date().toDateString(),
              disableReactions: true,
            },
          ];
        })
        .finally(() => {
          this.showSend = true;
        });
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Quicksand", sans-serif;
  padding: 0;
  margin: 0;
}
.msg-style {
  background-color: var(--chat-message-bg-color);
  border-radius: 5px;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--backgroung);
  color: var(--backgroung);
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--backgroung);
  color: var(--backgroung);
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
</style>
